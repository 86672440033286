import React from 'react'
import { Layout, Card, Row, Col } from 'antd'

const { Footer } = Layout
const MainFooter = () => {
  return (
    <Footer>
      <Row>
        <Col>
          <div className="d-flex flex-column text-center">
            <a className="link-white font-weight-300" href="https://wa.me/27796460712">Need help? WhatsApp our support line.</a>
            <a className="link-white font-weight-300" href="./terms-and-conditions" >Ts & Cs apply.</a>
          </div>
        </Col>
      </Row>
    </Footer>
  )
}

export default MainFooter
