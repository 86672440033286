/* eslint-disable no-plusplus */
import React, { Component, Fragment } from 'react'
import { Form, Icon, Button, Card } from 'antd'
import PropTypes from 'prop-types'

let id = 1
class DynamicFields extends Component {
  add = () => {
    const { getFieldValue, setFieldsValue, name } = this.props
    const keys = getFieldValue(`${name}List`)
    const nextKeys = keys.concat(id++)

    setFieldsValue({
      [`${name}List`]: nextKeys
    })
  }

  remove = k => () => {
    const { getFieldValue, setFieldsValue, name } = this.props
    const keys = getFieldValue(`${name}List`)

    if (keys.length === 1) return
    setFieldsValue({
      [`${name}List`]: keys.filter(key => key !== k)
    })
  }

  defaultValidation = name => ({
    validateTrigger: ['onChange', 'onBlur'],
    rules: [
      {
        required: true,
        whitespace: true,
        message: `Please input ${name}.`
      }
    ]
  })

  addSingleField = () => {
    const { getFieldDecorator, getFieldValue, fields: obj, name } = this.props
    getFieldDecorator(`${name}List`, { initialValue: [0] })
    const fieldCounter = getFieldValue(`${name}List`)
    return fieldCounter.map(k => (
      <Form.Item key={k}>
        {getFieldDecorator(
          `${name}[${k}]`,
          obj.validation || this.defaultValidation(name)
        )(obj.field())}
        {fieldCounter.length > 1 ? (
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={this.remove(k)}
          />
        ) : null}
      </Form.Item>
    ))
  }

  addMultipleFields = () => {
    const { getFieldDecorator, getFieldValue, fields, name } = this.props
    getFieldDecorator(`${name}List`, { initialValue: [0] })
    const fieldCounter = getFieldValue(`${name}List`)

    return fieldCounter.reduce((preResult, k) => {
      const row = fields.map((obj, i) => (
        <Form.Item key={`${k}${obj.name}`}>
          {getFieldDecorator(
            `${name}[${k}][${obj.name}]`,
            obj.validation || this.defaultValidation(name)
          )(obj.field())}
          {fieldCounter.length > 1 && fields.length - 1 === i ? (
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              onClick={this.remove(k)}
            />
          ) : null}
        </Form.Item>
      ))

      return [...preResult, ...row]
    }, [])
  }

  render() {
    const { fields  } = this.props
    return (
      <>

        {Array.isArray(fields)
          ? this.addMultipleFields()
          : this.addSingleField()}
        <Form.Item>
          <Button type="dashed" onClick={this.add} style={{ width: '100%', borderRadius: '30px' }}>
            <Icon type="plus" />
            Click here to add more codes
            
          </Button>
        </Form.Item>
        
      </>
    )
  }
}

DynamicFields.propTypes = {
  name: PropTypes.string.isRequired,
  fields: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ]).isRequired,
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired
}

export default DynamicFields