/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Typography, Statistic, Icon, Card, Col, Row, Progress, Alert } from 'antd';
import { Link } from 'react-router-dom';
import wheel from "shared/assets/wheel.png";

const { Title } = Typography

class Contact extends Component {
  render() {
    return (
      <>
        <div className="container-fluid page-header">
          <Row>
            <Col>
              <h1>
                <span className="font-weight-300">Contact</span><br/>Us
              </h1>
            </Col>
          </Row>
        </div>

        <div className="container-fluid p-3">
          <Row>
            <Col>
              <p>For help on the Exide-ing app, contact the Exide-ing team,</p>
              <p>
                <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="16.999" height="17" viewBox="0 0 16.999 17"><path id="FontAwsome_comment-alt_" data-name="FontAwsome (comment-alt)" d="M14.874,0H2.125A2.127,2.127,0,0,0,0,2.125v9.562a2.127,2.127,0,0,0,2.125,2.125H5.312V16.6a.4.4,0,0,0,.634.322l4.147-3.111h4.781A2.127,2.127,0,0,0,17,11.687V2.125A2.127,2.127,0,0,0,14.874,0Z" fill="#ef2924"/></svg></span>
                <span>Text us on WhatsApp: <a href="https://wa.me/27796460712" className="link-black font-weight-700">079 646 0712</a></span>
              </p>
              <p>
              <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="18.999" height="14.249" viewBox="0 0 18.999 14.249"><path id="FontAwsome_envelope_" data-name="FontAwsome (envelope)" d="M18.639,68.705a.223.223,0,0,1,.36.174v7.588a1.782,1.782,0,0,1-1.781,1.781H1.781A1.782,1.782,0,0,1,0,76.468V68.883a.222.222,0,0,1,.36-.174c.831.646,1.933,1.466,5.718,4.215.783.571,2.1,1.774,3.421,1.766,1.325.011,2.672-1.217,3.425-1.766C16.709,70.175,17.808,69.351,18.639,68.705ZM9.5,73.5c.861.015,2.1-1.084,2.724-1.536,4.924-3.573,5.3-3.885,6.434-4.776a.888.888,0,0,0,.341-.7v-.705A1.782,1.782,0,0,0,17.218,64H1.781A1.782,1.782,0,0,0,0,65.781v.705a.893.893,0,0,0,.341.7c1.135.887,1.51,1.2,6.434,4.776C7.4,72.416,8.639,73.514,9.5,73.5Z" transform="translate(0 -64)" fill="#ef2924"/></svg></span>
              <span >Email: <a className="link-black font-weight-700" href="mailto:simone@gullanandgullan.com">simone@gullanandgullan.com</a></span>
              </p>
            </Col>
          </Row>

          <Row>
             <div className="bg-primary rounded py-3 px-2 my-5 d-flex justify-content-center">
                <Link to={'./merch'}>
                  <div className="position-relative align-coin">
                    <img className="img-fluid" src={wheel} alt="" />
                    <h2 className="text-white my-2">
                      <span className="font-weight-300">Ready, set, </span>spin!
                    </h2>
                  </div>
                </Link>
            </div>
          </Row>

          <Row>
           <div className="bg-primary p-3 block-rounded text-white mb-2 box arrow-top">
            <h6 className="font-weight-700 text-uppercase text-white">Did you know?</h6>
            <p className="text-xs font-weight-300 mb-0">For every Exide battery sold, you earn one spin, and one entry to the grand prize draw.</p>
            </div>
          </Row>
        </div>
      </>
    )
  }
}

export default Contact
