import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  Spin
} from "antd";
import { connect } from 'react-redux';
import * as action from "./state/index";
import PropTypes from 'prop-types';

//import Roulette from 'react-roulette-game';

//import highlight_img from "shared/assets/wheel/hightlight.png";
import pointer_img from "shared/assets/wheel/pointer.png";
import roulette_img_under_highlight from "shared/assets/wheel/rou_under_high.png";
//import roulette_img_on_highlight from "shared/assets/wheel/rou_on_high.png";

import eprize1 from 'shared/assets/prize-img/eprize1.png'
import eprize2 from 'shared/assets/prize-img/eprize2.png'
import airtime from 'shared/assets/prize-img/airtime.svg'
import kfc from 'shared/assets/prize-img/kfc.svg'
import checkers from 'shared/assets/prize-img/checkers.svg'

import Roulette from './Roulette';


class CustomizedForm extends React.Component {

  render() {
    const { getFieldDecorator, validateFields } = this.props.form;
    const { onCancel, onCreate, form, visible } = this.props;

    return (
      <Modal
        visible={visible}
        closable={false}
        okText="Add to cart"
        width="416px"
        // onCancel={onCancel}
        onOk={onCreate}
        //cancelText='Upload invoice'
        okText='Redeem'
        wrapClassName="ant-modal-form"
        className="ant-modal-prize"
        //cancelButtonProps={{ className: "ant-btn-round ant-btn-lg" }}
        cancelButtonProps={{ className: "d-none" }}
        okButtonProps={{ type: 'secondary', className: "ant-btn-round ant-btn-lg" }}
      >
        <div>
          <h1 className="mb-2">Aweh!</h1>
          <h5 className="mb-3">YOU WON A VOUCHER!</h5>
          <p classname="px-2" style={{ margin: '1rem auto'}}>
            <strong>R20 airtime voucher</strong><br/>sent to your number.
          </p>
         <div className="mb-4"><img className="img-fluid" style={{ width:'80px'}}  src={airtime}/></div>
         <p className="px-4 text-left">
           <strong>Please note:</strong><br/>
          Vouchers will be sent to your registered cell phone number. Vouchers to be sent in 72 hours.
         </p>
          <Form className="px-3">
            <Form.Item>
            {getFieldDecorator('network', {
              valuePropName: 'value',
              rules: [
                {
                  required: true, 
                  message: 'Select mobile network'
                },
              ],
              })(
              <Select allowClear placeholder="Select mobile network">
                <Select.Option value="mtn">MTN</Select.Option>
                <Select.Option value="vodacom">Vodacom</Select.Option>
                <Select.Option value="cell-c">Cell C</Select.Option>
                <Select.Option value="telkom">Telkom</Select.Option>
              </Select>
            )}
            </Form.Item>
          </Form>
         </div>
      </Modal>
    );
  }
}

const EnhancedForm = Form.create()(CustomizedForm);

class Wheel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      prize:'', 
      setPrize: '',
      prizeImg: '', 
      setPrizeImg: '',
      visible: false,
      visible2: false, 
      quantity: 1,
      email: null,
      ref_id: null
    }
  }

  componentDidMount() {
    const { getProducts, getUserProfile } = this.props;
    getProducts();
    getUserProfile();
  }

  addToCart({ id, name, redemption_amount, image, email, ref_id }) {
    const {
      addItemToStore,
      currentBalance,
      updateCurrentBalance,
      currentTotal
    } = this.props;

    const Email = this.state.email;
    const Quan = this.state.quantity;
    const RefId = this.state.ref_id;

    addItemToStore({
        id,
        quantity: Quan,
        name,
        price: redemption_amount,
        //image,
        email: Email,
        ref_id: RefId,
    });

    updateCurrentBalance(currentBalance - redemption_amount * Quan);
  }

  performCheckout = () => {
    const { checkout, cart, profile, success } = this.props;
    
    var cartItems = cart?.cartItems;
    var userId = profile.id;
    
    checkout(cartItems, userId);
    //update(cartItems, userId);

    cartItems.forEach(cartItem => {
      this.removeFromCart(cartItem.id)
    });
  };

  removeFromCart(product_id) {
    const { removeItemFromStore } = this.props;
    removeItemFromStore(product_id);
  }

  handleFormCreate = () => {
    const { form } = this.formRef.props;
    const { update } = this.props;
    const { ref_id, visible } = this.state

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.setState({ 
        visible: false
      });

      update(values, ref_id);
      form.resetFields();

      const $ = s => document.querySelector(s);
      $('.reset-btn').click();

    });
  };

  handleFormCancel = () => {
    const { form } = this.formRef.props;
    const { update } = this.props;
    const { ref_id, visible } = this.state

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.setState({ 
        visible: false
      });

      update(values, ref_id);
      
      form.resetFields();

      const $ = s => document.querySelector(s);
      $('.reset-btn').click();

      const { history } = this.props;
      history.push("/invoice");
    });
 
  };

  handleOk = () => {
    this.setState({ 
      visible2: false 
    });

    const $ = s => document.querySelector(s);
    $('.reset-btn').click();
  };

  handleCancel = () => {
    this.setState({ 
      visible2: false 
    });

    const { history } = this.props;
    history.push("/invoice");
  };

  render() {
    const { products, currentBalance, cart, profile } = this.props;
    const { id, name, redemption_amount, image, email, ref_id } = this.state;
    const { prize, setPrize, prizeImg, setPrizeImg, visible, visible2 } = this.state;

    const modalcontent = '';

    const on_complete = (prize) => {

      console.log(prize?.id)

      // 27 airtime
      //
      // 26 e-prize 1
      // 28 kfc
      // 30 checkers
      // 31 e-prize 2

  
      if (prize?.id === 26 ||
          prize?.id === 28 ||
          prize?.id === 30 ||
          prize?.id === 31 ) {

          this.setState({
            visible2: true,
            setPrize: prize?.name,
            ref_id: parseInt(Math.floor(100000000 + Math.random() * 900000000))
          })

          this.addToCart(prize);
          this.performCheckout();

          let prizecontent = '';

          //e-prize
          if (prize?.id == 26) {
            prizecontent = (
              <div>
                <h1 className="mb-0">E-Card</h1>
                <div><img className="img-fluid" style={{ maxWidth:'255px'}}  src={eprize2}/></div>
                <p className="px-3">Another customer's Exide-ing journey made possible with your charm. Keep going and spinning, you could win more.</p>
              </div>
            )
          }

          //e-prize 2
          if (prize?.id == 31) {
            prizecontent = (
              <div>
                <h1 className="mb-0">E-Card</h1>
                <div><img className="img-fluid" style={{ maxWidth:'255px'}}  src={eprize1}/></div>
                <p className="px-3">You won a slice, keep trying. You could win even more.</p>
              </div>
            )
          }

          //kfc
          if (prize?.id == 28) {
            prizecontent = (
              <div>
                <h1 className="mb-2">Aweh!</h1>
                <h5 className="mb-3">YOU WON A VOUCHER!</h5>
                <p className="px-2" style={{ margin: '1rem auto'}}>
                  <strong>R50 KFC meal voucher</strong><br/>to keep you finger lickin' good.
                </p>
               <div><img className="img-fluid my-4" style={{ width:'200px'}} src={kfc}/></div>
               <p className="px-4 text-left">
                 <strong>Please note:</strong><br/>
                 Vouchers will be sent to your registered cell phone number. Vouchers to be sent in 72 hours.
                </p>
              </div>
            )
          }

          //pnp
          if (prize?.id == 30 ) {
            prizecontent = (
              <div>
                <h1 className="mb-2">Aweh!</h1>
                <h5 className="mb-3">YOU WON A VOUCHER!</h5>
                <p className="px-2" style={{ margin: '1rem auto'}}>
                  <strong>R100 Checkers voucher</strong><br/>just for you.
                </p>
            
               <div>
                 <img className="img-fluid my-4" style={{ width:'159px'}} src={checkers}/>
                </div>
                <p className="px-4 text-left">
                <strong>Please note:</strong><br/>
                 Vouchers will be sent to your registered cell phone number. Vouchers to be sent in 72 hours.
                </p>
              </div>
            )
          }

          const modalcontent = prizecontent;

          Modal.confirm({
            visible: this.state.visible2,
            content: modalcontent,
            cancelText: 'Upload invoice',
            okText: 'Spin again',
            className: 'ant-modal-prize',
            cancelButtonProps: { className: "ant-btn-round ant-btn-lg d-none" },
            okButtonProps: { type: 'secondary', className: "ant-btn-round ant-btn-lg"},
            onCancel: () =>{
              this.handleCancel();
            },
            onOk: () => {
              this.handleOk();
            }
          });
      } 

      else if (prize?.id === 27) {
        //airtime
        this.setState({
          visible: true,
          setPrize: prize?.name,
          ref_id: parseInt(Math.floor(100000000 + Math.random() * 900000000))
        })

        this.addToCart(prize);
        this.performCheckout();
      }
    };

    const roulette_props = {
      roulette_img_under_highlight,
      //roulette_img_on_highlight,
      //highlight_img,
      pointer_img,
      prize_arr: products,
      on_complete,
      has_reset: true,
      start_text: 'Spin'
    };


    return (
      <>
        <div className="game-box">
          {/* {products ? <Roulette {...roulette_props} /> : null }*/}
          { products.length > 0 ? <Roulette spins={currentBalance} {...roulette_props} /> :  <Spin />}

        </div>
        <EnhancedForm
          wrappedComponentRef={form => (this.formRef = form)}
          visible={this.state.visible}
          onCancel={this.handleFormCancel}
          onCreate={this.handleFormCreate}
          />
      </>
    );
  }
}

//export default Wheel;

const mapStateToProps = ({
  shared: {
    item: { item },
    list: { listItems },
    cart
  },
}) => ({
  products: listItems,
  profile: item, 
  currentBalance: cart.currentBalance,
  cart
});

const mapDispatchToProps = action;

export default connect(mapStateToProps, mapDispatchToProps)(Wheel);