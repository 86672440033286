import { combineReducers } from "redux";
import * as create from "shared/state/reducerFactory";

export default combineReducers({
  list: create.list({ path: "shared" }),
  form: create.form({ path: "shared" }),
  item: create.item({ path: "shared" }),
  items: create.items({ path: "shared" }),
  vouchers: create.vouchers({ path: "shared" }),
  reps: create.reps({ path: "shared" }),
  branches: create.branches({ path: "shared" }),
  cart: create.cart(),
});
