/* eslint-disable react/jsx-wrap-multilines */
import React from 'react'
import PropTypes from 'prop-types'
import { Button, Result } from 'antd'

const NotFoundPage = ({ history }) => (
  <Result
    status="404"
    title="404"
    subTitle="not found"
    extra={
      <Button type="primary" onClick={history.goBack}>
        Home
      </Button>
    }
  />
)

NotFoundPage.propTypes = {
  history: PropTypes.object.isRequired
}

export default NotFoundPage
