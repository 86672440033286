import React, { Component, useState } from "react";
import { connect, useSelector } from "react-redux";
import * as action from "./state/index";
import {
  Spin,
  Typography,
  Statistic,
  Icon,
  Input,
  Card,
  Col,
  Button,
  Form,
  Row,
  Progress,
  Alert,
  Modal
} from "antd";
import ReturnButton from "./return";
import coin from "shared/assets/coin.png";


class Cart extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { getUserProfile } = this.props;
    getUserProfile();
  }
  
  removeFromCart(product_id) {
    const { removeItemFromStore } = this.props;
    removeItemFromStore(product_id);
  }

  removeFromCartButton(product_id) {
    const { removeItemFromStoreButton } = this.props;
    removeItemFromStoreButton(product_id);
  }

  performCheckout = () => {
    const { checkout, cart, profile, success } = this.props;

    var cartItems = cart?.cartItems;
    var userId = profile.id;

    const currentBalance =  profile.wallet?.balance
    const currentTotal = cart?.cartItems?.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);

    //if (currentBalance >= currentTotal) {

      checkout(cartItems, userId)

      cartItems.forEach(cartItem => {
        this.removeFromCart(cartItem.id)
      });

    // } else {
    //   var num1, num2;
    //   num1 = currentBalance;
    //   num2 = currentTotal;
    //   var difference = function (num1, num2){
    //     return Math.abs(num1 - num2);
    //   }

    //   const earn = difference(num1,num2)
    //   const modalcontent = `Earn ${earn}BB to complete this transaction!`

    //   Modal.info({
    //     title: 'Energise your earnings',
    //     content: modalcontent,
    //     okText: 'Ok',

    //     onOk(){},
    //   });
    // }
    
  };

  render() {
    const { cart, profile, submitting } = this.props;

    return (
      <>
        <div className="container-fluid page-header">
          <ReturnButton />
          <Col>
            <h1>
              <span className="font-weight-300">Your</span><br/>Exide-ing Cart
            </h1>
          </Col>
        </div>
        <div className="container-fluid p-3 bg-white flex-grow-1 d-flex flex-column">
         <Row>
            <Col>
              <p className="pt-20">This Exide-ing voucher can help you energise your earnings.</p>
            </Col>
         
            <Col>
              { this.props.cart?.cartItems.length ? this.props.cart?.cartItems?.map((item, i) => (
                <Card key={i} bordered={false} className="ant-card-grey ant-card-cart">
  
                  <Icon 
                    key={i}
                    onClick={() => this.removeFromCartButton(i)}
                    type="close"
                    />

                    <div className="d-flex">
                      <div className="voucher-img-wrapper">
                        <img
                            width="160px"
                            height="auto"
                            className="img-fluid mb-0"
                            src={`https://exide-app-receipts.s3.af-south-1.amazonaws.com/${item?.image}`}
                          />
                      </div>
                      <div className="text-right ml-auto">
                        <div>
                          <h6 className="font-weight-700 text-uppercase text-xs">Qty: {item.quantity}</h6>
                        </div>
                        <div>
                          <h6 className="font-weight-700 text-uppercase text-xs">{item.name}</h6>
                        </div>
                        { item.email ?
                           <div><h6 className="font-weight-700 text-uppercase text-xs">Email: {item.email}</h6></div>
                          : <div></div>
                        }
                        <div className="d-flex align-items-center justify-content-end">
                          <img className="mb-0 mr-2" width="35" src={coin} alt="" />
                          <p className="text-sm text-uppercase font-weight-700 mb-0">{item.price} BB</p>
                        </div>
                      </div>
                    </div>
                </Card>
              ))
              : <p className="font-weight-700 mt-4"><em>Your cart is empty</em></p> }
            </Col>
          </Row>
        </div>

        <div className="container-fluid p-3 bg-white">
          <Row>
            <Col>
            <p className="text-sm mb-0 font-weight-700"><em>Please note:</em></p>
            <p className="text-sm"><em>Delivery takes up to 10 working days from date of order.</em></p>
            </Col>
          </Row>
        </div>

        <div className="container-fluid p-3">
          <Row>
            <Col span={12} className="d-flex">
              <h6 className="text-uppercase font-weight-700 mr-2">Total:</h6>
              <h6 className="mb-0 font-weight-300">({cart?.itemsInCart } items)</h6>
            </Col>
            <Col span={12}>
              <h3 style={{float: 'right', paddingRight: '60px'}}>
                {cart?.cartItems?.reduce(
                  (acc, curr) => acc + curr.price * curr.quantity, 0
                )}BB{" "}

                </h3>
            </Col>
          </Row>

          <Row>
            {
              this.props.cart?.cartItems.length ?  
              <Button className="w-100 ant-btn ant-btn-primary" onClick={this.performCheckout}>
              {/*{ submitting ? <Spin className="submitting-color" /> : "Checkout" }*/}
              Checkout
              </Button>
              : <Button className="w-100 ant-btn ant-btn-primary disabled">Checkout</Button>
            }
          </Row>
        </div>
      </>
    );
  }
}

// const mapStateToProps = ({ shared: { item, cart } }) => ({
//   item, cart
// });

const mapStateToProps = ({
  shared: {
    item: { item },
    form: { submitting, success },
    cart
  },
}) => ({
  profile: item,
  submitting,
  success,
  cart,
});

const mapDispatchToProps = action;

export default connect(mapStateToProps, mapDispatchToProps)(Cart);