import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Row, Col } from 'antd'
import ReturnButton from "./return";
import battery2 from 'shared/assets/batteries/exide-enhanced-flooded-battery.svg'
import battery1 from 'shared/assets/batteries/exide-silver-calcium.svg'
import battery3png from 'shared/assets/batteries/sr-1.png'

class Education extends Component {

render() {
    return (
      <>
        <div className="container-fluid page-header">
          <ReturnButton />
           <Col>
            <h1>
              <span className="font-weight-300">Learn more. Sell more.</span><br/>Earn more
            </h1>
          </Col>
        </div>

        <div className="container-fluid p-3 pt-4">
            <Row>
                <Col>
                  <p>The more you know about Exide batteries, the better you'll be able to assist your customers and earn more Spins.</p>
                </Col>
            </Row>
        </div>

        <div className="container-fluid py-5 px-3 bg-grey-100">
            <Row>
              <Col>
                <div className="text-center battery-education-panel">
                  <img src={battery1} />
                  <h3 className="my-4 font-weight-700 text-uppercase">Exide Silver Calcium</h3>
                  <p>Maintenance-free, perfect for cars and trucks without Start/Stop technology.</p>
                </div>
              </Col>
            </Row>
        </div>

        <div className="container-fluid py-5 px-3">
          <Row>
            <Col>
              <div className="text-center battery-education-panel">
                <img src={battery2} />
                <h3 className="my-4 font-weight-700 text-uppercase">Exide Enhanced Flooded Battery</h3>
                <p>Designed for cars equipped with Start/Stop technology, but not regenerative braking.</p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="container-fluid py-5 px-3 bg-grey-100">
          <Row>
            <Col>
              <div className="text-center battery-education-panel">
                <img src={battery3png}/>
                <h3 className="my-4 font-weight-700 text-uppercase">Exide Valve Regulated Lead Acid or AGM Technology</h3>
                <p>Designed for higher-end cars with technical systems such as brake regeneration and Start/Stop technology.</p>
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default connect(

)(Education)