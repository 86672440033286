/* eslint-disable import/no-mutable-exports */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import {
  Spin,
  Card,
  Col,
  Row,
  Form,
  Modal,
  Icon,
  Input,
  Button,
  Checkbox,
  Select
} from 'antd'
import MainFooter from 'shared/components/layouts/MainFooter'
import { register } from 'account/state/accountActions'
import { Link } from 'react-router-dom'
import logo from 'shared/assets/logo-light.svg'
import batterybucks from 'shared/assets/batterybucks-logo.svg'

const { Option } = Select

let UserRegister = ({
  regBranches,
  regReps,
  form: { getFieldDecorator, validateFields },
  register,
  push,
  submitting
}) => {

  const handleSubmit = e => {
    e.preventDefault()
   
    validateFields((err, values) => {
      if (!err) {
        let newValues = [values]
        register(newValues)
      }
    }) 
  }

  let stores = [];

  let branchesHtml = [];
  let branches = regBranches;
 
  for (let b in branches) {
    branchesHtml.push(<Option key={branches[b].id} value={branches[b].id}>{branches[b].display_name}</Option>)
  }

  let repsHtml = [];
  let reps = regReps;

  for (let r in reps) {
    repsHtml.push(<Option key={reps[r].id} value={reps[r].id}>{reps[r].name} {reps[r].surname}</Option>);   
  }

  let locationsHtml = [];
  const locations = [
    {
        id: 1,
        label: 'Western Cape'
    },
    {
        id: 2,
        label: 'Gauteng'
    },
    {
        id: 3,
        label: 'Eastern Cape'
    },
    {
        id: 4,
        label: 'Northern Cape'
    },
    {
        id: 5,
        label: 'Kwazulu Natal'
    },
    {
        id: 6,
        label: 'North West Province'
    },
    {
        id: 7,
        label: 'Freestate'
    },
    {
        id: 8,
        label: 'Limpopo'
    },
    {
        id: 9,
        label: 'Mpumalanga'
    }
]

  for (let l in locations) {
    locationsHtml.push(<Option key={locations[l].id} value={locations[l].label}>{locations[l].label}</Option>);   
  }

  const [selectedOption, setSelectedOption] = useState("none");

  const handleTypeSelect = e => {
    setSelectedOption(e.value);
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Row>
          <Col span={24} className="text-center mb-4">
            <a href="./">
              <img src={logo} alt="Exide" />
            </a>
          </Col>

          <Col span={24}>
            <Form onSubmit={handleSubmit} className="login-form">
            <Form.Item>
                {getFieldDecorator &&
                  getFieldDecorator('name', {
                    rules: [
                      { required: true, message: 'Please input your name!' }
                    ]
                  })(
                    <Input
                    style={{ borderRadius: 30 }}
                      placeholder="Name"
                      size="large"
                      className="mb-3"
                    />
                  )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator &&
                  getFieldDecorator('surname', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your surname!'
                      }
                    ]
                  })(
                    <Input
                      style={{ borderRadius: 30 }}
                      type="surname"
                      placeholder="Surname"
                      size="large"
                      className="mb-3"
                    />
                  )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator &&
                  getFieldDecorator('cellphone', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your cell phone number!'
                      }
                    ]
                  })(
                    <Input
                      style={{ borderRadius: 30 }}
                      type="text"
                      placeholder="Cell phone number"
                      size="large"
                      className="mb-3"
                    />
                  )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator &&
                getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: 'The input is not valid email!',
                    },
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ],
                })(<Input
                  style={{ borderRadius: 30 }}
                  type="email"
                  placeholder="Email"
                  size="large"
                  className="mb-3"
                
                />)}
              </Form.Item>
             {/* <Form.Item>
                {getFieldDecorator &&
                  getFieldDecorator('store_id', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select your store!'
                      }
                    ]
                  })(
                    <Select
                      placeholder="Select store"
                      size="large"
                      className="mb-3"
                      onChange={handleTypeSelect}
                    >
                      {stores}
                    </Select>
                  )}
              </Form.Item>*/}
               <Form.Item>
                {getFieldDecorator &&
                  getFieldDecorator('location', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select your region!'
                      }
                    ]
                  })(
                    <Select
                      placeholder="Select region"
                      size="large"
                      className="mb-3"
                      onChange={handleTypeSelect}
                    >
                      {locationsHtml}
                    </Select>
                  )}
              </Form.Item>
              
              <Form.Item>
                {getFieldDecorator &&
                  getFieldDecorator('branch_id', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select your branch!'
                      }
                    ]
                  })(
                    <Select
                      placeholder="Select branch"
                      size="large"
                      className="mb-3"
                      onChange={handleTypeSelect}
                    >
                      {branchesHtml}
                    </Select>
                  )}
              </Form.Item>
              
              <Form.Item>
                {getFieldDecorator &&
                  getFieldDecorator('fnb_rep_id', {
                    rules: [
                      {
                        required: false,
                        message: 'Please select your FNB Rep!'
                      }
                    ]
                  })(
                    <Select
                      placeholder="Select FNB Rep"
                      size="large"
                      className="mb-3"
                      onChange={handleTypeSelect}
                    >
                    {repsHtml}
                    </Select>
                  )}
              </Form.Item>

              <Form.Item className="opt-in pl-2 mb-4">
                {getFieldDecorator &&
                  getFieldDecorator('opt_in', {
                    rules: []
                  })(
                  <Checkbox>I agree to receive communications from Exide and First National Battery.</Checkbox>
                  )}
              </Form.Item>

              <Form.Item className="text-center">
                <Button
                  type="secondary"
                  htmlType="submit"
                  shape="round"
                  //block
                  className="login-submit"
                  size="large"
                >
                 Register
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  )
}

UserRegister.defaultProps = {
  submitting: false,
  performLogin: () => {}
}

UserRegister.defaultProps = {
  push: () => {}
}

UserRegister.propTypes = {
  //submitting: PropTypes.bool,
  register: PropTypes.func,
  form: PropTypes.object.isRequired,
  push: PropTypes.func
}

UserRegister = Form.create({ name: 'register' })(UserRegister)

export default UserRegister
