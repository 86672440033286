/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "./state/index";
import {
  Typography,
  Statistic,
  Icon,
  Card,
  Col,
  Row,
  Form,
  Input,
  Modal,
  Progress,
  Alert,
} from "antd";

import ReactHtmlParser from 'react-html-parser';
import coin from "shared/assets/coin.png";
import ReturnButton from "./return";

const { Title } = Typography;

const { confirm } = Modal;

const EmailCreateForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      
      return (
        <Modal
          visible={visible}
          closable={false}
          okText="Add to cart"
          onCancel={onCancel}
          onOk={onCreate}
        >
        <div className="mb-3"><span className="ant-modal-confirm-title">Add to cart</span></div>
        <div className="mb-3">
          <span className="ant-modal-confirm-content">
            Takealot vouchers will be sent to your email address. Please ensure you enter the correct email.
          </span>
        </div>
          <Form>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [{ 
                  required: true, 
                  type: "email",
                  message: 'Please add an email address!' 
                }],
              })(<Input placeholder="Email..."/>)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  },
);

class SingleMerch extends Component {
  
  constructor(props) {
    super();

    this.state = {
      quantity: 1,
      email: null,
      visible: false
    };
  }

  showModal = ({ id, name, redemption_amount, image, email }) => {
    this.setState({ 
      visible: true,
      name,
      id,
      name, 
      redemption_amount, 
      image, 
      email
    });
  };

  handleCancel = () => {
    this.setState({ 
      visible: false 
    });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    const { id, name, redemption_amount, image, email } = this.state

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      form.resetFields();

      this.setState({ 
        visible: false 
      });

      const { history } = this.props;

      const {
        addItemToStore,
        currentBalance,
        updateCurrentBalance,
        currentTotal,
        item
      } = this.props;

      const Email = values.email;
      const Quan = this.state.quantity;

      //!IMPORTANT @tapiwa check the math here

      if (
        currentBalance <= currentTotal ||
        currentBalance <= currentBalance - currentTotal + redemption_amount
      ) {
        return;
      }

      addItemToStore({
        id,
        quantity: Quan,
        name,
        price: redemption_amount,
        image,
        email: Email,
      });

      updateCurrentBalance(currentBalance - redemption_amount * Quan);

      history.push("/cart");

    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  
  componentDidMount() {
    const { match: { params }, getProducts } = this.props;
    //fetchSingleMerch(params.id);
    getProducts();
  }

  add = () => {
    this.setState((prevState) => ({ quantity: prevState.quantity + 1 }));
  };

  remove = () => {
    if(this.state.quantity > 1 ){
      this.setState((prevState) => ({ quantity: prevState.quantity - 1 }));
    }
  };

  addToCart({ id, name, redemption_amount, image, email }) {
   
    const { history } = this.props;
    
    const {
      addItemToStore,
      currentBalance,
      updateCurrentBalance,
      currentTotal,
    } = this.props;
    const Email = this.state.email;
    const Quan = this.state.quantity;

    //!IMPORTANT @tapiwa check the math here
    if (
      currentBalance <= currentTotal ||
      currentBalance <= currentBalance - currentTotal + redemption_amount
    ) {
      return;
    }

    const modalcontent = `You’ve added ${name} your cart`

    Modal.confirm({
      title: "Added to your cart",
      content: modalcontent,
      okText: 'Checkout',
      cancelText: 'Continue shopping',

      onOk() {
        addItemToStore({
          id,
          quantity: Quan,
          name,
          price: redemption_amount,
          image,
          email: Email,
        });

        updateCurrentBalance(currentBalance - redemption_amount * Quan);

        history.push("/cart");
      },

      onCancel() {
        addItemToStore({
          id,
          quantity: Quan,
          name,
          price: redemption_amount,
          image,
          email: Email,
        });

        updateCurrentBalance(currentBalance - redemption_amount * Quan);
      
      },
    });

  }
  render() {
    const { item, products, id } = this.props;
    const param = Number(this.props.match.params.id);
    
    var product = products.filter(function (product) {
      return product.id === param;
    });

    return (
      <>
        <div className="container-fluid page-header">
          <ReturnButton />
          <Col>
           {product?.map((product, i) => (
              <h1 key={i} className="font-weight-300">{product.name}</h1>
            ))}
          </Col>
        </div>

         {product?.map((product, i) => (
            <div key={i} className="container-fluid p-3 flex-grow-1 d-flex flex-column">
              <Row className="d-flex">
                <Col className="d-flex align-items-center">
                  <img className="mb-0 mr-2" width="45" src={coin} alt="" />
                  <p className="text-sm text-uppercase font-weight-700 mb-0">{product?.redemption_amount} BB</p>
                </Col>
              {/*
                <Col className="d-flex align-items-center ml-auto">
                    <p className="text-sm text-uppercase font-weight-700 mb-0 mr-2">Qty: </p>
                    <a className="ant-btn ant-btn-primary" onClick={this.add}>+</a>
                    <span className="pl-2 pr-2"> {this.state.quantity} </span>
                    <a className="ant-btn ant-btn-primary" onClick={this.remove}>-</a>
                </Col>*/}
              </Row>
              <Row>
                <div className="mb-4">
                   <img
                      height="auto"
                      className="d-block mx-auto"
                      src={`https://exide-app-receipts.s3.af-south-1.amazonaws.com/${product?.image}`}
                    />
                </div>
              </Row>
              <Row>
                <Col>
                  { ReactHtmlParser(product?.description) }
                </Col>
              </Row>

              <Row className="mb-4">
                 { product.id === 23 || product.id === 24 || product.id === 25 ? 
                      <div>
                        <a className="w-100 ant-btn ant-btn-primary" href="#" onClick={() => this.showModal(product)}>
                          Redeem This Voucher
                        </a>
                      </div> 
                      : <div>
                          <a className="w-100 ant-btn ant-btn-primary" href="#" onClick={() => this.addToCart(product)}>
                            Redeem This Voucher
                          </a>
                       </div>
                    }
              </Row>

              <EmailCreateForm
                wrappedComponentRef={this.saveFormRef}
                visible={this.state.visible}
                onCancel={this.handleCancel}
                onCreate={this.handleCreate}
              />
              
            </div>
         ))}
      </>
    );
  }
}

const mapStateToProps = ({
  shared: {
    list: { listItems }
  },
}) => ({
  products: listItems
});

const mapDispatchToProps = action;

export default connect(mapStateToProps, mapDispatchToProps)(SingleMerch);