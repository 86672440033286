/* eslint-disable import/no-mutable-exports */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Form, Icon, Input, Button, Checkbox } from 'antd';
import MainFooter from 'shared/components/layouts/MainFooter';
import { Link } from 'react-router-dom';
import logo from 'shared/assets/logo-light.svg';
//import batterybucks from 'shared/assets/batterybucks-logo.svg'
import keyvisual from "shared/assets/key-visual.png";

let UserLogin = ({
  submitting,
  form: { getFieldDecorator, validateFields },
  performLogin,
  push,
}) => {
  const handleSubmit = e => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        performLogin(values)
      }
    })
  }
  
  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Row>
          <Col span={24} className="logo-lg text-center mb-4">
            <a href="./">
              <img src={logo} alt="Exide" />
            </a>
          </Col>
          <Col span={24} className="mb-4">
            <h1 className="text-center text-white my-2">
              <span className="display-1 font-weight-700">Spinna</span><br/>
              <span className="display-2 font-weight-700">and be a big</span><br/>
              <span className="display-1 font-weight-700">Winna</span>
            </h1>
          </Col>
          <Col span={24} className="login-form-wrap">
            <Form onSubmit={handleSubmit} className="login-form">
                <Form.Item>
                  {getFieldDecorator &&
                    getFieldDecorator('cellphone', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your cellphone!'
                        }
                      ]
                    })(
                      <Input
                        style={{ borderRadius: 30 }}
                        size="large"
                        placeholder="Mobile No."
                      />
                    )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator &&
                    getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your Password!'
                        }
                      ]
                    })(
                      <Input
                        style={{ borderRadius: 30 }}
                        type="password"
                        size="large"
                        placeholder="Password"
                      />
                    )}
                </Form.Item>
                <Form.Item className="text-center">
                   <Button
                    type="secondary"
                    htmlType="submit"
                    shape="round"
                    //block
                    size="large"
                    //loading={submitting}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            <Row>
              <div className="text-center">
                <a className="link-white text-underline font-weight-300" href="./reset">Forgot password?</a>
              </div>
            </Row>
          </Col>
        {/*  <Col span={24} className="text-center">
            <img className="img-fluid" src={keyvisual} alt="Exide"/>
          </Col>*/}
        </Row>
      </div>
    </div>
  )
}

UserLogin.defaultProps = {
  submitting: false,
  performLogin: () => {}
}

UserLogin.defaultProps = {
  push: () => {}
}

UserLogin.propTypes = {
  submitting: PropTypes.bool,
  performLogin: PropTypes.func,
  form: PropTypes.object.isRequired,
  push: PropTypes.func
}

UserLogin = Form.create({ name: 'login' })(UserLogin)

export default UserLogin
