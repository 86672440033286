import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Result, Button } from 'antd'

class Confirm extends Component {

render() {

return (
  <Result
    status="success"
    title="Congrats"
    subTitle="Our next campaign starts 9 November. Keep an eye out closer to the time for an SMS with your password."
  />
)

}

}

export default connect(

)(Confirm)