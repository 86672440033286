/* eslint-disable lines-between-class-members */
/* eslint-disable react/prop-types */
/* eslint-disable react/state-in-constructor */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Select, Card, Row, Col, Cascader, Button } from 'antd'
import * as actions from './state/index'

const { Option } = Select

const Voucher = ({ purchaseVoucher, vouchers }) => {
  
  const [voucherData, updateVoucherData] = useState([])

  console.log(voucherData.length)
  
  const handleChange = value => {
    updateVoucherData(value)
  }

  return (
    <>
      {/* <Card className="m-3">
        <h1 className="text-center">R{balance}</h1>
        <p className="text-center">Available</p>
      </Card> */}

      <Card className="m-3">
        <div>
          <Cascader
            style={{ width: '100%', marginBottom: '20px' }}
            options={vouchers}
            onChange={handleChange}
            changeOnSelect
          />
          <Button
            onClick={() => purchaseVoucher(voucherData)}
            type="primary"
            disabled={voucherData.length < 3}
            block
          >
            Purchase
          </Button>
        </div>
      </Card>
    </>
  )
}

const mapStateToProps = ({ shared }) => ({
  ...shared.item.item,
  ...shared.vouchers
})

const mapDispatchToProps = actions

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Voucher)

