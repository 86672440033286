import React from 'react'
import ReactDOM from 'react-dom'
import { Redirect, push, replace, goBack } from 'connected-react-router'
import { buildUrl } from 'shared/api/utils'
import storage from 'shared/lib/storage'
import { storeToken, deleteToken } from 'shared/api/tokens'
import request from "shared/api/request"
import { modal, notification } from "antd"


export const updateHash = hash => (dispatch, getState) => {
  const currentUrl = getState().routing.locationBeforeTransitions
  dispatch(replace(`${currentUrl.pathname}${hash}`))
}

export const updateQuery = query => (dispatch, getState) => {
  const currentUrl = getState().router.location
  query = {
    ...currentUrl.query,
    ...query
  }
  dispatch(replace(buildUrl(currentUrl.pathname, query)))
  return query
}

export const selectToken = (token, redirectPath) => dispatch => {
  storage.local.setObj('user', () => token)
  storeToken(token)
  
  if (redirectPath) {
    dispatch(push(redirectPath))
  }
}

export const logOut = () => dispatch => {
  deleteToken()
}

export const setPageTitle = title => dispatch => {
  dispatch({
    type: 'SET_PAGE_TITLE',
    title
  })
}

export const back = goBack
