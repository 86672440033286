import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Layout,
  Icon,
  Drawer,
  Button,
  Menu,
  Row,
  Col,
  PageHeader,
  Badge,
  Modal
} from "antd";
import { connect } from "react-redux";
import logo from "shared/assets/logo-dark.svg";
import menuIcon from "shared/assets/menu-icon.svg";
import cartIcon from "shared/assets/shopping-cart-icon.svg";
import { getModules } from "shared/static/modules";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
// import { logOut } from "shared/state/actions"
import { storeToken, deleteToken } from 'shared/api/tokens'

const { Header, Sider } = Layout;
const { SubMenu } = Menu;
const modules = getModules("123");
// const logout = logOutModal();

const LogoutLink = (props) => {
   const history = useHistory();

    const handleClick = () => {
      Modal.confirm({
        title: 'LEAVING SO SOON?',
        content: (
          <div>
            <p>Learn more, sell more and spin more to be the bigga winna.</p>
          </div>
        ),
        cancelText: 'Spin again',
        okText: 'Logout',
        cancelButtonProps: { type: 'primary', className: "ant-btn-round ant-btn-lg" },
        okButtonProps: { type: 'primary', className: "ant-btn-round ant-btn-lg" },
        onCancel() {
           history.push("/merch");
            const $ = s => document.querySelector(s);
            $('.ant-drawer-close').click();
        },
        onOk() {
          deleteToken()
          window.location.reload()
        }
      });
    }

    return (
        <Link onClick={handleClick}>
          {/*<Icon type="logout" />*/}
          <span>
            Logout
          </span>
        </Link>
    );
}

class Nav extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    current: "mail",
    visible: false,
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChildrenDrawerClose = () => {
    this.setState({
      childrenDrawer: false,
    });
  };

  render() {
    const { counter } = this.props;

    return (
      <nav className="menuBar">
        <Header>
          <Row gutter={0}>
            <Col span={6}>
              <a className="burger-menu" onClick={this.showDrawer}>
                <img src={menuIcon} alt="Exide" />
              </a>
            </Col>
            <Col span={12}>
              <div className="logo text-center">
                <a href="/dashboard">
                  <img src={logo} alt="Exide" />
                </a>
              </div>
            </Col>
            <Col span={6}>
             {/* <a className="cartIcon" href="/cart">
                <img src={cartIcon} alt="Exide" />
                <Badge size="default" count={counter} />
              </a>*/}
            </Col>
          </Row>
        </Header>

        <div className="menuCon">
          <div className="leftMenu" />
            <Drawer
             width={305}
              title="Navigation"
              placement="left"
              closable
              onClose={this.onClose}
              visible={this.state.visible}
            >
              {modules.length ? (
                <span>
                  <Menu theme="light" mode="vertical">
                    {modules.map(({ key, link, title, icon, sub, className }, idx) =>
                      sub && sub.length > 0 ? (
                        <SubMenu
                          key={`sub${idx}`}
                          title={
                            <span>
                              <Icon type={icon} />
                              <span>{title}</span>
                            </span>
                          }
                        >
                          {sub &&
                            sub.map((item, index) => (
                              <Menu.Item
                                key={`${item.title.replace(/\s/g, "-")}${index}`}
                                to="taps"
                                onClick={this.onClose}
                              >
                                <Icon type={item.icon} />
                                <span>
                                  <Link to={item.link} className="sub-menu-row">
                                    {item.title}
                                  </Link>
                                </span>
                              </Menu.Item>
                            ))}
                        </SubMenu>
                      ) : (
                        <Menu.Item 
                          key={`${key}${idx}`}
                          onClick={this.onClose}
                          className={className}
                        >
                          <Link to={link}>
                           {/* <Icon type={icon} />*/}
                            <span>
                              {title}
                            </span>
                          </Link>
                        </Menu.Item>
                      )
                    )}

                    <Menu.Item 
                    key="logout"
                    className="logout"
                    >
                     <LogoutLink />
                    </Menu.Item>

                    <Menu.Item 
                    key="tandcs"
                    onClick={this.onClose}
                    className="tandcs"
                    >
                      <Link to="/terms-and-conditions">
                        {/*<Icon type="logout" />*/}
                        <span>
                          Terms & Conditions
                        </span>
                      </Link>
                    </Menu.Item>

                  </Menu>
                </span>
              ) : null }

             <div className="logo-md p-2 mt-auto">
              <img src={logo} alt="Exide" />
             </div>
            </Drawer>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = ({ shared: { cart } }) => ({
  counter: cart.itemsInCart,
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
