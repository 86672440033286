import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import * as actions from '../state/accountActions'
import StoreUserRegister from './registerStore'

class StoreUserRegisterContainer extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { fetchBranches, fetchReps } = this.props;
    fetchBranches();
    fetchReps();
  }

  render() {
    const { regBranches, regReps } = this.props;

    return (
          <StoreUserRegister {...this.props} />
      );
  }
}

const mapStateToProps = ({
  shared: {
    form: { submitting },
    branches: branches,
    reps: reps,
  }
}) => ({
  submitting,
  regBranches: branches.branchesItem,
  regReps: reps.repsItem,
})

const mapDispatchToProps = { ...actions, push }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreUserRegisterContainer)
