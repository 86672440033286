/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Form, Icon, Input, Button, Checkbox } from 'antd'
import MainFooter from 'shared/components/layouts/MainFooter'
import { Link } from 'react-router-dom'
import logo from 'shared/assets/logo-light.svg'

let PasswordReset = ({
  submitting,
  form: { getFieldDecorator, validateFields },
  createReset,
  push
}) => {
  const handleSubmit = e => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        createReset(values)

        console.log(values);
      }
    })
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Row>
          <Col span={24} className="text-center mb-4">
           <a href="./">
              <img src={logo} alt="Exide" />
            </a>
          </Col>
          <Col span={24}>
            <Card title="Reset Password" bordered={false} className="mb-3">
              <Form onSubmit={handleSubmit} className="login-form">
                <Form.Item>
                  {getFieldDecorator &&
                    getFieldDecorator('email', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your cellphone!'
                        }
                      ]
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        size="large"
                        placeholder="Cellphone"
                      />
                    )}
                </Form.Item>

                <Form.Item>
                  <Button
                    type="secondary"
                    htmlType="submit"
                    block
                    icon="login"
                    //loading={submitting}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col span={24} className="text-center">
            <Link to="/login" className="link-white text-underline font-weight-300">Registered? Login</Link>
          </Col>
        </Row>
      </div>
    </div>
  )
}

PasswordReset.defaultProps = {
  submitting: false,
  performLogin: () => {}
}

PasswordReset.defaultProps = {
  push: () => {}
}

PasswordReset.propTypes = {
  submitting: PropTypes.bool,
  performLogin: PropTypes.func,
  form: PropTypes.object.isRequired,
  push: PropTypes.func
}

PasswordReset = Form.create({ name: 'login' })(PasswordReset)

export default PasswordReset