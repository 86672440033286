/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Button, Form, Typography, Statistic, Icon, Card, Col, Row, Progress, Alert } from 'antd'
import buyMerchButton from "shared/assets/buy-exide-ing-merch.svg";
import dyk from "shared/assets/dyk/contact-dyk.svg";
import logo from "shared/assets/logo-light.svg";
import batterybucks from 'shared/assets/batterybucks-logo.svg'

const { Title } = Typography

class SplashScreen extends Component {
  render() {
    return (
        <div className="auth-wrapper">
        <div className="auth-content">
        <Row>
            <Col className="text-center">
                <img src={logo}/>
            </Col>
        </Row>
         
        <Row style={{ paddingTop: '60px'}}>
            <Col >
            <h3 style={{
                color: '#fff'
            }} className="text-center title-no-weight mb-0">LEARN, EARN AND GET</h3>
                <h3 style={{
                color: '#fff'
            }} className="text-center  title-bold">BATTERY BUCKS MERCH</h3>
            </Col>
        </Row>

        <Row style={{ paddingTop: '30px'}}>
            <Col>
            <Form.Item>
                  <Button
                  href="./login"
                    type="primary"
                    block
                    className="login-submit"
                    size="large"
                  >
                    Login
                  </Button>
                </Form.Item>
            </Col>
        </Row>

        <Row>
            <Col>
            <Form.Item>
                  <Button
                  href="/exide-register/register/individual"
                    type="primary"
                    block
                    className="login-submit"
                    size="large"
                  >
                    Register as Individual
                  </Button>
                </Form.Item>
            </Col>
        </Row>


            <Row>
                <Col>
                    <Form.Item>
                        <Button
                            href="/exide-register/register/store"
                            type="primary"
                            block
                            className="login-submit"
                            size="large"
                        >
                            Register as Store Manager
                        </Button>
                    </Form.Item>
                </Col>
            </Row>

</div></div>
    )
  }
}

export default SplashScreen
