/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable import/no-mutable-exports */
import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Spin,
  Typography,
  Form,
  Input,
  Button,
  Select,
  Card,
  Row,
  Col,
  Upload,
  Icon,
  message,
  DatePicker
} from 'antd'
import PropTypes from 'prop-types'
import Webcam from 'react-webcam'
import * as actions from './state/index'
import DynamicFields from './DynamicFields'
import ReturnButton from "./return"
//import { orderBy } from 'natural-orderby';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import { formatDate, parseDate } from 'react-day-picker/moment';

const { TextArea } = Input

const { Title } = Typography
const { Option } = Select

let InvoiceForm = ({
  form,
  createinvoice,
  fetchBatteryCodes,
  fetchBranches,
  regBranches,
  codes,
  submitting
}) => {
  const [imageUrl, handleImgUrl] = useState(null)
  
  const [encodedImageUrl, handleEncodedImgUrl] = useState('')
  
  const [loading, handleLoading] = useState(false)
  
  const [isButtonLoading, setIsButtonLoading] = React.useState(false)

  useEffect(() => {
    fetchBatteryCodes()
  }, [fetchBatteryCodes])

  useEffect(() => {
    fetchBranches()
  }, [fetchBranches])

  function handleDayChange(value) {
    const dateval = moment(value);
    const dateformatted = dateval.format("YYYY-MM-DD");

    form.setFieldsValue({
      date_of_sale: dateformatted
    });
  }
  
  const handleSubmit = e => {
    e.preventDefault()

    form.validateFields((err, values) => {
      if (err) {
        console.log('Received values of form: ', values)
        return
      }
      
      createinvoice(values, encodedImageUrl)
    })
  }

  // const getBase64 = (img, callback) => {
  //   const reader = new FileReader()
  //   reader.addEventListener('load', () => callback(reader.result))
  //   reader.readAsDataURL(img)
  // }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const beforeUpload = file => {
    
    const allowed = [
      'text/x-comma-separated-values',                                            
      'text/comma-separated-values',                                              
      'application/x-csv',                                                       
      'text/x-csv',                                                            
      'text/csv',                                                                 
      'application/csv',                                                         
      'text/plain',                                
      'image/jpeg',                                                          
      'image/pjpeg',                                                              
      'application/pdf',                                                          
      'application/octet-stream',                                                  
      'image/png',                                                
      'image/x-png',                                                              
      'application/msexcel',                                                    
      'application/x-msexcel',                                                    
      'application/x-ms-excel',                                                 
      'application/x-excel',                                                       
      'application/x-dos_ms_excel',                                               
      'application/xls',                                                          
      'application/x-xls',                                                        
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',    
      'application/vnd.ms-excel'                
    ]

    const type = file.type

    if (allowed.indexOf(type) == -1) {
      message.error('This file type is not allowed!')
    }
  
    const isLt2M = file.size / 1024 / 1024 < 3
    
    if (!isLt2M) {
      message.error('Image must smaller than 3MB!')
    }

    // getBase64(file, imageUri => {
    //   handleEncodedImgUrl(imageUri)
    // })

      getBase64(file).then(
        //data => console.log(data)
        data => handleEncodedImgUrl(data)
      );

      handleImgUrl(file)

      return false
  }

  const uploadButton = (
    <div style={{ borderRadius: '30px' }}>
      <Icon type={loading ? 'loading' : 'plus'} />
    </div>
  )

  const dump = codes 
  ? Object.entries(codes).map(data => (
        <Option key={data[0]} value={data[1]}>
          { data[1]} 
        </Option>
      ))
  : []

  // for (let b in branches) {
  //   branchesHtml.push(<Option key={branches[b].id} value={branches[b].id}>{branches[b].display_name}</Option>)
  // }

  const dump_branches = regBranches
  ? Object.entries(regBranches).map(data => (
        <Option key={data[1].id} value={data[1].id}>
          { data[1].display_name} 
        </Option>
      ))
  : []

  const [selectedOption, setSelectedOption] = useState("none");
  

  //const list = ['10.04f', '10.039F', '10.038d', '10.037D']

  const UploadSvg = () => (
    <svg width="20.542px" height="20.539px" viewBox="0 0 20.542 20.539">
      <path fill="#EF2924" d="M18.135,18.613c0-0.441-0.361-0.803-0.803-0.803c-0.44,0-0.802,0.361-0.802,0.803
      c0,0.44,0.361,0.802,0.802,0.802C17.773,19.415,18.135,19.054,18.135,18.613z M15.567,18.613c0-0.441-0.361-0.803-0.803-0.803
      s-0.803,0.361-0.803,0.803c0,0.44,0.361,0.802,0.803,0.802S15.567,19.054,15.567,18.613z M20.542,15.082v4.494
      c0,0.533-0.429,0.963-0.963,0.963H0.963C0.43,20.539,0,20.109,0,19.576v-4.494c0-0.533,0.43-0.963,0.963-0.963H6.42v0.321
      c0,1.239,1.007,2.247,2.246,2.247h3.21c1.24,0,2.247-1.008,2.247-2.247v-0.321h5.456C20.113,14.119,20.542,14.549,20.542,15.082z
        M11.876,15.403h-3.21c-0.533,0-0.963-0.43-0.963-0.963V7.7H4.185c-0.714,0-1.07-0.862-0.565-1.368l6.103-6.106
      c0.301-0.301,0.794-0.301,1.095,0l6.106,6.106C17.429,6.838,17.071,7.7,16.357,7.7h-3.519v6.74
      C12.839,14.974,12.41,15.403,11.876,15.403z"/>
    </svg>
  );

  const UploadIcon = props => <Icon component={UploadSvg} {...props} />;

  return (
    <>
    <div className="container-fluid page-header">
     <ReturnButton />
      <Row>
        <Col>
          <h1>
            <span className="font-weight-300">Upload, earn,</span><br/>spin and win!
          </h1>
        </Col>
      </Row>
    </div>

    <div className="container p-3">
      {/* <Row>
        <Col>
        <ul className="my-4">
          <li>Thank you for taking part in our Exide-ing Summer campaign.</li>
          <li>The cut off for uploading invoices was 5pm on the 4th February.</li>
          <li>You still have till 5pm on 11 February to redeem your last spins, and keep winning our Exide-ing voucher prizes.</li>
          <li>The Grand Prize draw will take place on Friday 18th February and we will contact our winning Midas store directly.</li>
        </ul>
        </Col>
      </Row> */}

      <Row>
        <Col>
          <Form
            //layout="vertical"
            onSubmit={handleSubmit}
            className="invoice-form ant-form-grey mt-3"
          >
          <Row gutter={16}>
            {/* <Col span={24}>
              <Form.Item>
                  {form.getFieldDecorator('branch_id', {
                    rules: [
                        {
                          // required: true,
                          message: 'Please select your branch!'
                      }
                    ]
                  })(<Select
                      placeholder="Midas branch name"
                      size="large"
                      showArrow
                        >
                        {dump_branches}
                    </Select>)}
                </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item>
                {form.getFieldDecorator('date_of_sale', {
                  rules: [
                      {
                        required: true,
                        message: 'Please enter date of purchase!'
                      }
                    ]
                  })
                (
                  <DayPickerInput
                    inputProps={{ className: 'ant-calendar-picker-input ant-input'}}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder="Date of sale"
                    format="YYYY-MM-DD"
                    onDayChange={handleDayChange}
                  />
                  )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {form.getFieldDecorator('receipt_number', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a valid invoice number!'
                    }
                  ]
                })(<Input placeholder="Invoice no." />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                  {form.getFieldDecorator('quantity', {
                    rules: [
                        {
                          required: true,
                          message: 'Please input your units sold!'
                        }
                    ]
                  })(<Input placeholder="Units sold"/>)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {form.getFieldDecorator('items', {
                  rules: [
                      {
                        required: true,
                        message: 'Please input your battery codes!'
                    }
                  ]
                })(<Select
                      placeholder="Battery codes"
                      mode="multiple"
                      showArrow
                      >
                      {dump}
                  </Select>)}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item className="mb-2">
                {form.getFieldDecorator('upload', {
                  rules: [
                    {
                        required: true,
                        message: 'Please upload your invoice!'
                    }
                  ]
                })(
                  <Upload beforeUpload={beforeUpload}>
                    <Button className="ant-btn-input">
                     Upload invoice <span className="input-help-text ml-1">(JPEG, PNG, PDF, Excel or CSV allowed)</span><UploadIcon />
                     {/* <Icon type="upload" /> */}
                    </Button>
                  </Upload>,
                )}
              </Form.Item>
              <p className="text-sm mt-1 mb-4 text-center">Max file size <strong>3MB</strong>.</p>
            </Col>
            </Row>

            <Form.Item className="text-center">
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                shape="round"
                //onClick={setSubmittState}
                loading={submitting}
              >
              Submit
              </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="text-sm mt-3"><strong>Remember</strong>, the invoice you upload must be clear and legible. <strong><em>No photos of computer screens.</em></strong></p>
          </Col>
        </Row>

         <Row>
            <Col>
              <div className="bg-primary p-3 block-rounded text-white mt-3 mb-2 box arrow-top">
                <h6 className="font-weight-700 text-uppercase text-white">Did you know?</h6>
                <p className="text-xs font-weight-300 mb-0">The more you learn, the more you sell, the more you spin and the more you win!</p>
              </div> 
            </Col>
          </Row>

      </div>
    </>
  )
}

InvoiceForm = Form.create({ name: 'invoice' })(InvoiceForm)

const mapStateToProps = ({
  shared: {
    item: { item },
    form: { submitting },
    branches: branches,
  }
}) => ({
  codes: item,
  regBranches: branches.branchesItem,
  submitting
})

const mapDispatchToProps = actions

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceForm)