/* eslint-disable react/display-name */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

// Layouts
import BasicLayout from "shared/components/layouts/BasicLayout";
import EmptyLayout from "shared/components/layouts/EmptyLayout";
import PrivateRoute from "shared/components/Authorization/PrivateRoute";
import InvoiceForm from "modules/invoice";
import Wallet from "modules/wallet";
import Profile from "modules/profile";
import Voucher from "modules/voucher";
import Contact from "modules/contact";
import Education from "modules/education";
import OrderThankYou from "modules/thank-you-page";
import TsandCs from "modules/terms-and-conditions";
import Merch from "modules/merch-page";
import SingleMerch from "modules/merch-single-page";
import SplashScreen from "modules/splash-screen";
import Cart from "modules/cart";

import Dashboard from "./modules/home";

// Login

import Landing from "./account/components/landing";
import Register from "./account/components/register";
import UserLogin from "./account/components/loginContainer";
import UserRegister from "./account/components/registerIndividualContainer";
import StoreUserRegister from "./account/components/registerStoreContainer";
import ResetContainer from "./account/components/resetContainer";

// logout
import { logOut } from "shared/state/actions";

// Confirm

import Confirm from "modules/confirm";

// Exception
import NotFoundPage from "./shared/components/exceptions/404";
import invoiceConfirm from "modules/invoice-confirm";

const Wrapper = ({ wrapper: Wrapped, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Wrapped>
        <Component {...props} />
      </Wrapped>
    )}
  />
);

const form = (Form, type, options) => (props) => (
  <Form {...props} formType={type} {...options} />
);

const Routes = ({ history }) => (
  <Switch history={history}>
    <Redirect 
      exact 
      from="/" 
      to="/dashboard"
    />
    <Wrapper
      exact
      path="/welcome"
      wrapper={EmptyLayout}
      component={Landing}
    />
    <Wrapper 
      exact 
      path="/login" 
      wrapper={EmptyLayout} 
      component={UserLogin} 
    />
  
     <Wrapper
      exact
      path="/register"
      wrapper={EmptyLayout}
      component={Register}
    />
    <Wrapper
      exact
      path="/register-individual"
      wrapper={EmptyLayout}
      component={UserRegister}
    />
    <Wrapper
      exact
      path="/register-store-manager"
      wrapper={EmptyLayout}
      component={StoreUserRegister}
    />
    <Wrapper
      exact
      path="/order-confirmation"
      wrapper={BasicLayout}
      component={OrderThankYou}
    />
    <Wrapper
      exact
      path="/reset"
      wrapper={EmptyLayout}
      component={ResetContainer}
    />*/}
    <Wrapper exact path="/confirm" wrapper={EmptyLayout} component={Confirm} />
    <PrivateRoute
      exact
      path="/invoice-confirm"
      wrapper={EmptyLayout}
      component={invoiceConfirm}
    />
    <PrivateRoute
      exact
      path="/education"
      wrapper={BasicLayout}
      component={Education}
    />
     <PrivateRoute
      exact
      path="/merch"
      wrapper={BasicLayout}
      component={Merch}
    />
    <PrivateRoute
      exact
      path="/single-merch"
      wrapper={BasicLayout}
      component={SingleMerch}
    />
     <PrivateRoute
      exact
      path="/single-merch/:id"
      wrapper={BasicLayout}
      component={SingleMerch}
    />
    <PrivateRoute
      exact
      path="/terms-and-conditions"
      wrapper={BasicLayout}
      component={TsandCs}
    />
    <PrivateRoute exact path="/merch" wrapper={BasicLayout} component={Merch} />
    <PrivateRoute
      exact
      path="/dashboard"
      wrapper={BasicLayout}
      component={Dashboard}
    />
    <PrivateRoute
      exact
      path="/profile"
      wrapper={BasicLayout}
      component={Profile}
    />
    <PrivateRoute
      exact
      path="/invoice"
      wrapper={BasicLayout}
      component={InvoiceForm}
    />
    <PrivateRoute
      exact
      path="/voucher"
      wrapper={BasicLayout}
      component={Voucher}
    />
    <PrivateRoute exact path="/cart" wrapper={BasicLayout} component={Cart} />
    <PrivateRoute
      exact
      path="/contact"
      wrapper={BasicLayout}
      component={Contact}
    />
    <PrivateRoute
      exact
      path="/wallet"
      wrapper={BasicLayout}
      component={Wallet}
    />
    <Wrapper wrapper={EmptyLayout} component={NotFoundPage} />
  </Switch>
);

Routes.propTypes = {
  history: PropTypes.object.isRequired,
};

Wrapper.propTypes = {
  wrapper: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

export default Routes;
