import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import * as actions from '../state/authActions'
import Reset from './reset'

const PasswordResetContainer = props => {
  return <Reset {...props} />
}

const mapStateToProps = ({
  shared: {
    form: { submitting }
  }
}) => ({
  submitting
})

const mapDispatchToProps = { ...actions, push }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetContainer)