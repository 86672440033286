import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import {ReactComponent as IconArrowLeft} from "shared/assets/icon-arrow-left.svg";

function Return (props) {
  const history = useHistory();

  const routeChange = () => {
    history.goBack()
  };

  return (
    <Button 
      onClick={routeChange}
      className="ant-btn-return"
    >
      <IconArrowLeft />
    </Button>
  );
}

class ReturnButton extends Component {
  render() {

    return (
       <Return />
    );
  }
}

export default ReturnButton;


