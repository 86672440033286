/* eslint-disable no-undef */
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { ConnectedRouter as Router } from "connected-react-router";
import configureStore, { history } from "./store";
import * as serviceWorker from "./serviceWorker";
import Routes from "./routes";
import "./index.less";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { unregister } from './serviceWorker';
import * as action from "./modules/state/index";

const store = configureStore();
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <Routes history={history} />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

unregister();

// serviceWorker.register({
//   onUpdate: (registration) => {
//     const waitingServiceWorker = registration.waiting;

//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener("statechange", (event) => {
//         if (event.target.state === "activated") {
//           window.location.reload();
//         }
//       });
//       waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//     }
//   },
// });


