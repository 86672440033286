import { transform } from "lodash";

const initialListState = {
  listItems: [],
  pagination: {},
  fetching: true,
};

export const list = ({ path, pathReset }) => (
  state = initialListState,
  action
) => {
  switch (action.type) {
    case `${path}/list/FETCHED_ITEMS`:
      // eslint-disable-next-line no-case-declarations
      const { items = [], pagination } = action;
      return { ...state, listItems: items, pagination, fetching: false };
    case `${path}/list/FETCHING`:
      return { ...state, listItems: [], fetching: true };
    case `${path}/list/FETCH_ERROR`:
      return { ...state, fetching: false };
    case `${pathReset ? `${path}/list/RESET` : "ROUTE_CHANGED"}`:
      return initialListState;
    default:
      return state;
  }
};

const initialFormState = {
  formItem: {},
  formLoaded: false,
  submitting: false,
  messages: [],
};

export const form = ({ path, pathReset }) => (
  state = initialFormState,
  action
) => {
  switch (action.type) {
    case `${path}/form/FETCHING`:
      return { ...state, ...initialFormState };
    case `${path}/form/FETCHED_ITEM`:
      return {
        ...state,
        formItem: action.item,
        formLoaded: true,
        submitting: false,
      };
    case `${path}/form/SUBMITTING`:
      return { ...state, submitting: true };
    case `${path}/form/SUBMITTED`:
      return { 
        ...state, 
        submitting: false, 
        messages: action.messages || [] 
      };
    case `${pathReset ? `${path}/form/RESET` : "ROUTE_CHANGED"}`:
      return initialFormState;
    default:
      return state;
  }
};

const initialItemState = {
  item: {},
  itemLoaded: false,
};

export const item = ({ path, pathReset }) => (
  state = initialItemState,
  action
) => {
  switch (action.type) {
    case `${path}/item/FETCHING`:
      return { ...state, ...initialItemState };
    case `${path}/item/FETCHED_ITEM`:
      return { ...state, 
        item: action.item,
        itemLoaded: true
      };
    case `${pathReset ? `${path}/item/RESET` : "ROUTE_CHANGED"}`:
      return initialItemState;
    default:
      return state;
  }
};

const initialItemsState = {
  getItems: (state, itemTypes) =>
    transform(
      itemTypes,
      (ret, type) => {
        ret[type] = state[type] || [];
        ret[`${type}Loaded`] = !!state[`${type}Loaded`];
      },
      {}
    ),
    itemsLoaded: false,
};

export const items = ({ path, pathReset }) => (
  state = initialItemsState,
  action
) => {
  switch (action.type) {
    case `${path}/items/FETCHING`:
      return { ...state, ...initialItemsState };
    case `${path}/items/FETCHED_ITEMS`:
      return transform(
        action.items,
        (ret, collection, type) => {
          ret[type] = collection;
          ret[`${type}Loaded`] = true;
        },
        { ...state, itemsLoaded: true }
      );
    case `${pathReset ? `${path}/items/RESET` : "ROUTE_CHANGED"}`:
      return initialItemsState;
    default:
      return state;
  }
};


const initialRepsState = {
  repsItem: {},
  repsLoaded: false,
};

export const reps = ({ path, pathReset }) => (
  state = initialRepsState,
  action
) => {
  switch (action.type) {
    case `${path}/reps/FETCHED_REPS`:
      return { ...state, 
        repsItem: action.reps, 
        repsLoaded: true 
      };
    case `${pathReset ? `${path}/reps/RESET` : "ROUTE_CHANGED"}`:
      return initialRepsState;
    default:
      return state;
  }
};

const initialBranchesState = {
  branchesItem: {},
  branchesLoaded: false,
};

export const branches = ({ path, pathReset }) => (
  state = initialBranchesState,
  action
) => {
  switch (action.type) {
    case `${path}/branches/FETCHED_BRANCHES`:
      return { ...state, 
        branchesItem: action.branches, 
        branchesLoaded: true 
      };
    case `${pathReset ? `${path}/branches/RESET` : "ROUTE_CHANGED"}`:
      return initialBranchesState;
    default:
      return state;
  }
};


const initialVoucherState = {
  vouchers: [
    {
      value: "SHOPRITE_DIGITAL_VOUCHER",
      label: "Shopping Voucher",
      children: [
        {
          value: "Checkers",
          label: "Checkers",
          children: [
            {
              value: "R100",
              label: "R100",
            },
            {
              value: "R500",
              label: "R500",
            },
            {
              value: "R1000",
              label: "R1000",
            },
          ],
        },
      ],
    },
    {
      value: "PINNED_AIRTIME",
      label: "Airtime Voucher",
      children: [
        {
          value: "MTN",
          label: "MTN",
          children: [
            {
              value: "MTNR10Airtime",
              label: "R10",
            },
            {
              value: "MTNR30Airtime",
              label: "R30",
            },
          ],
        },
        {
          value: "CellC",
          label: "Cell C",
          children: [
            {
              value: "CellCR10Airtime",
              label: "R10",
            },
            {
              value: "CellCR35Airtime",
              label: "R35",
            },
          ],
        },
        {
          value: "Telkom",
          label: "Telkom",
          children: [
            {
              value: "TelkomR10AIRTIME",
              label: "R10",
            },
            {
              value: "TelkomR30AIRTIME",
              label: "R30",
            },
          ],
        },
        {
          value: "Vodacom",
          label: "Vodacom",
          children: [
            {
              value: "VodacomR10Airtime",
              label: "R10",
            },
            {
              value: "VodacomR29Airtime",
              label: "R29",
            },
          ],
        },
      ],
    },
    {
      value: "PINLESS_DATA",
      label: "Data Bundle Voucher",
      children: [
        {
          value: "MTN",
          label: "MTN",
          children: [
            {
              value: "MTN100MBPINLESSData",
              label: "100MB-R29",
            },
            {
              value: "MTN750MBPINLESSData",
              label: "750MB-R120",
            },
          ],
        },
        {
          value: "CellC",
          label: "Cell C",
          children: [
            {
              value: "CellC100MBPINLESSData",
              label: "100MB-R29",
            },
            {
              value: "CellC1GBBundlePINLESSData",
              label: "1GB-R100",
            },
          ],
        },
        {
          value: "Telkom",
          label: "Telkom",
          children: [
            {
              value: "Telkom100MBPINLESSData",
              label: "100MB-R29.25",
            },
            {
              value: "Telkom1GBPINLESSData",
              label: "1GB+1GB-R100",
            },
          ],
        },
        {
          value: "Vodacom",
          label: "Vodacom",
          children: [
            {
              value: "Vodacom100MBPINLESSDATA",
              label: "100MB-R29",
            },
            {
              value: "Vodacom500MBPINLESSData",
              label: "500MB-R100",
            },
          ],
        },
      ],
    },
    {
      value: "KFC_DIGITAL_VOUCHER",
      label: "KFC Voucher",
      children: [
        {
          value: "KFC",
          label: "KFC",
          children: [
            {
              value: "R50",
              label: "R50",
            },
          ],
        },
      ],
    },
  ],
  itemLoaded: false,
};

export const vouchers = ({ path, pathReset }) => (
  state = initialVoucherState,
  action
) => {
  switch (action.type) {
    case `${path}/list/FETCHED_VOUCHERS`:
      return { ...state, vouchers: action.item, itemLoaded: true };
    case `${pathReset ? `${path}/list/RESET` : "ROUTE_CHANGED"}`:
      return initialVoucherState;
    default:
      return state;
  }
};

const initialCartState = {
  itemsInCart: 0,
  currentBalance: 0,
  currentTotal: 0,
  cartItems: [],
};

export const cart = () => (state = initialCartState, action) => {
  switch (action.type) {
    case "ADD_CART_ITEM":
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
        itemsInCart: state.itemsInCart + 1,
        currentTotal:
          state.currentTotal + action.payload.price * action.payload.quantity,
      };
    case "REMOVE_CART_ITEM_BUTTON":
      return {
        ...state,
        cartItems: state.cartItems.filter((_, i) => i !== action.payload),
        itemsInCart: state.itemsInCart - 1,
        currentTotal:
          state.currentTotal - action.payload.price * action.payload.quantity,
    };
    case "REMOVE_CART_ITEM":
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => item.id !== action.payload),
        itemsInCart: state.itemsInCart - 1,
        currentTotal:
          state.currentTotal - action.payload.price * action.payload.quantity,
    };
    case "UPDATE_WALLET_BALANCE":
      return {
        ...state,
        currentBalance: action.payload,
      };
    case "RESET_CART":
      return {
        ...initialCartState,
      };
    default:
      return state;
      console.log("cart state", state);
  }
};
