import React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import * as actions from '../state/authActions'
import LoginUser from './login'

function LoginBodyClass() {
 	useEffect(() => {
	  document.body.className = "page--login";
	  
	  return () => {
	    document.body.className = "";
	  }
	}, []);
}

const UserLoginContainer = props => {
	LoginBodyClass()
  	return (<LoginUser {...props} />)
}

const mapStateToProps = ({
  shared: {
    form: { submitting },
  }
}) => ({
  submitting,
})

const mapDispatchToProps = { ...actions, push }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserLoginContainer)

