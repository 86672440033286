import React from 'react'
import { Layout } from 'antd'
import MainFooter from './MainFooter'

const { Content } = Layout

const EmptyLayout = ({ children }) => {
  const layoutContent = (
    <Layout className="bg-color-none">
      <Content>{children}</Content>
      <MainFooter />
    </Layout>
  )

  return <div className="main-wrapper empty-layout bg-primary--gradient">{layoutContent}</div>
}

export default EmptyLayout
