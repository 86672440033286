/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import * as action from "./state/index";
import PropTypes from 'prop-types';
import {
  Typography,
  Statistic,
  Icon,
  Card,
  Form,
  Col,
  Row,
  Progress,
  Alert,
  Button,
  Input,
  Modal,
} from "antd";
import ReturnButton from "./return";
import coin from "shared/assets/coin.png";
import Slider from "react-slick";

import Wheel from './wheel';

// import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { info } = Modal;

class Merch extends Component {

  constructor(props) {
    super();

    this.state = {
      visible: false
    };
  }

  componentDidMount() {
    //const { getUserProfile } = this.props;
    //getUserProfile();
  }

  render() {
    const { currentBalance } = this.props;

    return (
      <>
        <div className="container-fluid page-header">
          <ReturnButton />
          <Col>
            <h1>
              <span className="font-weight-300"> Sell Exide. Earn Spins.</span><br/>Be a winna.
            </h1>
          </Col>
        </div>
        <div className="container-fluid p-3">
          <Row>
            <Col span={24} className="text-right">
              <p className="current-balance text-uppercase font-weight-700">Spins {currentBalance}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="text-center">
                <span className="font-weight-300">Spinna </span>and win!
              </h2>
              {/* <p className="text-center">
                Click the spin button now and stand a chance to WIN an Exide-ing voucher!
              </p> */}
            </Col>
          </Row>

          <Wheel {...this.props}/>

          <div className="bg-primary p-3 block-rounded text-white mx-3 mb-2 box arrow-top">
            <h6 className="font-weight-700 text-uppercase text-white">Did you know?</h6>
            <p className="text-xs font-weight-300 mb-0">The more Exide you sell, the bigga chance of winning you have.</p>
          </div> 
        </div>
      </>
    );
  }
}

// const mapStateToProps = state => {
//   const itemLoaded = state.shared.item.itemLoaded;
//   const wallet = state.shared.item.item?.wallet;

//   return {
//     wallet,
//     itemLoaded
//   };
// };


const mapStateToProps = ({
  shared: {
    item: { item },
    cart
  },
}) => ({
  profile: item,
  currentBalance: cart.currentBalance
});

const mapDispatchToProps = action;

export default connect(mapStateToProps, mapDispatchToProps)(Merch);
