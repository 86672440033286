/* eslint-disable import/no-mutable-exports */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Form, Icon, Input, Button, Checkbox } from 'antd';
import MainFooter from 'shared/components/layouts/MainFooter';
import { Link } from 'react-router-dom';
import logo from 'shared/assets/logo-light.svg';
//import batterybucks from 'shared/assets/batterybucks-logo.svg'
import keyvisual from "shared/assets/key-visual.png";


function LoginBodyClass() {
  useEffect(() => {
    document.body.className = "page--landing";
    
    return () => {
      document.body.className = "";
    }
  }, []);
}

const Landing = props => {

  LoginBodyClass()

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Row>
          <Col span={24} className="logo-lg text-center mb-4">
            <a href="./">
              <img src={logo} alt="Exide" />
            </a>
          </Col>
          <Col span={24} className="mb-4">
            <h1 className="text-center text-white my-2">
             <span className="display-1 font-weight-700">Spinna</span><br/>
             <span className="display-2 font-weight-700">and be a big</span><br/>
             <span className="display-1 font-weight-700">Winna</span>
            </h1>
          </Col>
          <Col span={24}>
            <Row className="landing-form-wrap">
              <div className="text-center mb-3">
                <Link className="ant-btn ant-btn-secondary ant-btn-round ant-btn-lg" to='/login'>Login</Link>
              </div>
              <div className="text-center">
               <Link className="ant-btn ant-btn-secondary ant-btn-round ant-btn-lg" to='/register'>Register</Link>
              </div>
            </Row>
          </Col>
        {/*  <Col span={24} className="text-center">
            <img className="img-fluid" src={keyvisual} alt="Exide"/>
          </Col>*/}
        </Row>
      </div>
    </div>
  )
}


export default Landing;