// import { isEmpty } from 'lodash'
import api from 'shared/api/request'
import { selectToken } from 'shared/state/actions'
import { push } from 'connected-react-router'
import { notification } from 'antd'

export const performLogin = data => dispatch => {
  dispatch({ type: 'shared/form/SUBMITTING' })
  api
    .post('/login', { data })
    .then(({ token }) => {
      if (typeof token !== 'string') {
        dispatch(push('/login'))

        notification.error({
          message: `Login Error`,
          description: 'Either your cellphone or password is incorrect'
        })

        dispatch({
          type: 'shared/form/SUBMITTED'
        })
      } else {
        console.log("first time login")
        dispatch({
          type: 'shared/form/SUBMITTED'
        })
        dispatch(selectToken(token, '/dashboard'))

      }
    })
    .catch(body => {
      dispatch({
        type: 'shared/form/SUBMITTED',
        messages: body
      })
    })
}

export const confirmReset = (email, token) => dispatch => {
  dispatch({ type: 'shared/form/SUBMITTING' })
  api
    .post(`/password/reset`, { data: { ...email, token } })

    .then(res => {
      //console.log(res);
      if (res.cellphone) {
        dispatch({
          type: 'shared/form/SUBMITTED'
        })
        notification.success({
          message: `Reset Success`,
          description: 'You will receive an SMS with a new password'
        })
        dispatch(push('/login'))
      }
    })
    .catch(({ error }) => {
      dispatch({ type: 'shared/form/SUBMITTED', messages: error })
    })
}

export const createReset = email => dispatch => {
  dispatch({ type: 'shared/form/SUBMITTING' })
  api
    .post(`/password/create`, { data: email })
    .then(({ token }) => {
      if (token) {
        return dispatch(confirmReset(email, token))
      }
      return false
    })
    .catch(({ error }) => {
      dispatch({ type: 'shared/form/SUBMITTED', messages: error })
    })
}
