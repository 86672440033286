import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon, Card, Col, Row, Progress, Alert, Collapse, Timeline, List, Typography, Input } from 'antd'
import * as actions from './state/index'
import { logOut } from 'shared/state/actions'

const { Panel } = Collapse


class Profile extends Component {

  componentDidMount() {
    const { getUserProfile } = this.props
    getUserProfile()
  }
  
  render() {
    const { profile, sales, history, wallet } = this.props
    const { surname, avatar, cellphone, name} = profile
    
    const getReceipts = sales && sales.map(item =>
      <Timeline.Item color="grey">Invoice# {item.receipt_number} {item.approved}</Timeline.Item>
    )

    const getVoucherPurchase = history && history.map(item =>
      <List.Item>{item.status} | {item.network} | {item.pin} | {item.reference}</List.Item>
    )

    return (
      <>
      <Row className="row mb-4 mt-4">
				<div className="col">
					<div className="card user-card user-card-3 support-bar1">
						<div className="card-body ">
							<div className="text-center">
								<h3 className="mb-1 mt-3 f-w-400">{name} {surname}</h3>
								<p className="mb-3 text-muted">{cellphone}</p>           
							</div>
						</div>
            <div className="card-footer bg-light">
							<div className="row text-center">
								<div className="col">
									<h6 className="mb-1">{wallet?.balance}</h6>
									<p className="mb-0">Wallet balance</p>
								</div>
								<div className="col">
									<h6 className="mb-1">{wallet?.pendingRewards}</h6>
									<p className="mb-0">Pending rewards</p>
								</div>
								<div className="col">
									<h6 className="mb-1">{wallet?.totalAmountRedeemed}</h6>
									<p className="mb-0">Total redeemed</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Row>

      <Row className="row mb-4 mt-4">
        <div className="col">
          <Collapse accordion>
            <Panel header="List of captured receipts" key="1">
              <Timeline>
              {getReceipts}
              </Timeline>
            </Panel>
    
            <Panel header="List of ordered Merch" key="2"> 
              <List
                  size="small"
                  bordered
               >
               {getVoucherPurchase}
              </List>
            </Panel>
          </Collapse>
        </div>
      </Row>
      </>
    )
  }
}

const mapStateToProps = ({
  shared: {
    item: { item }
  }
}) => ({
  profile: item,
  sales: item.receipts, // reciepts LOL
  history: item.vouchers,
  wallet: item.wallet
})

const mapDispatchToProps = actions

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)