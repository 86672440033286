import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Alert, Result, Button } from 'antd'

class ThankPage extends Component {

render() {

return (
  <>
  <Result
    status="success"
    title="Thank you"
    subTitle="Boom! Your Exide-ing order has been placed. Please note: Your order may take up to 4 weeks to be delivered."
    extra={[
      <Button href="./dashboard" type="default" key="console">
        Home
      </Button>
  ]}
  />
  </>
)

}

}

export default connect(

)(ThankPage)