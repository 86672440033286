import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

//import logger from 'redux-logger'

export const history = createBrowserHistory();

export default function configureStore(preloadedState = {}) {
  // eslint-disable-next-line no-undef
  const persistConfig = {
    key: "roots",
    storage: storage,
    blacklist: ["list", "form", "item", "items", "vouchers", "reps", "branches"],
  };

  const pReducer = persistReducer(persistConfig, createRootReducer(history));

  const composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    pReducer,
    preloadedState,
    composeEnhancer(applyMiddleware(routerMiddleware(history), thunkMiddleware)),
  );

  return store;
}
