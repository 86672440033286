/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Icon, Select } from 'antd'
import Nav from 'shared/components/Nav'
import PropTypes from 'prop-types'
import { logOut } from 'shared/state/actions'
import MainFooter from './MainFooter'

const { Header, Content } = Layout
const { Option } = Select

const BasicLayout = props => {
  const [collapsed, onCollapsed] = useState(false)

  const onCollapse = pred => {
    onCollapsed(pred)
  }

  const toggle = () => {
    onCollapsed(!collapsed)
  }

  const { LogOut, children } = props

  const layoutContent = (
    <Layout>
        {/* <span style={{ float: 'right', marginRight: '20px' }} onClick={LogOut}>
          Logout
        </span>
       */}
      <Nav isCollapsed={collapsed} OnCollapse={onCollapse} />
      <Content>{children}</Content>
      <MainFooter />
    </Layout>
  )

  return (
    <div className="main-wrapper basic-layout">{layoutContent}</div>
  )
}

BasicLayout.propTypes = {
  children: PropTypes.object.isRequired,
  LogOut: PropTypes.func.isRequired
}
export default connect(
  null,
  { LogOut: logOut }
)(BasicLayout)