import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Row, Col } from 'antd'
import battery2 from 'shared/assets/batteries/exide-enhanced-flooded-battery.svg'
import battery1 from 'shared/assets/batteries/exide-silver-calcium.svg'
import battery3 from 'shared/assets/batteries/exide-valve-regulated-lead-acid.svg'

class TermsAndConditions extends Component {

render() {

return (
    <>
      <div className="container-fluid page-header">
        <Row>
          <Col>
            <h1>
              <span className="font-weight-300">Terms and</span><br/>Conditions
            </h1>
          </Col>
        </Row>
      </div>
      <div className="container-fluid p-3 ant-layout-terms">
        <Row>
            <Col>
              <ol>
                <li>
                  <p>To qualify for the Exide-ing Spinna Winna Back By Popular Demand campaign, you must be a registered employee of Midas or NAPA with the following documentation,</p>
                  <ul>
                    <li><p className="mb-1">A South African ID</p></li>
                    <li><p className="mb-1">Work permit if from abroad</p></li>
                    <li><p className="mb-1">A South African cell phone number</p></li>
                  </ul>
                </li>
                <li>
                  <p>Store managers and individuals can register at www.exide-ing.co.za and fill in all required information.</p>
                </li>
                <li>
                  <p>Give consent to receive communication and share your details with Exide and First National Battery.</p>
                </li>
                <li>
                  <p>To earn spins, you must sell Exide batteries. (All other brands of batteries do not qualify.)</p>
                </li>
                <li>
                  <p>For every Exide battery you sell, earn one (1) spin.</p>
                </li>
                <li>
                  <p>Your spins are saved in your Exide-ing wallet. The following information is required,</p>
                  <ul>
                    <li><p className="mb-1">Invoice number</p></li>
                    <li><p className="mb-1">Date of sale</p></li>
                    <li><p className="mb-1">Battery code</p></li>
                    <li><p className="mb-1">Quantity of Exide batteries sold as per the invoice</p></li>
                    <li><p className="mb-1">Upload of your customer invoice (Jpeg, PNG, PDF or Excel)<br/> Please note: Invoice uploads must be clear and legible. Photographs of computer screens will not be accepted.</p></li>
                  </ul>
                </li>
                <li>
                  <p>Only invoices from the campaign period 1 July 2022 to 30 September 2022 are valid.</p>
                </li>
                <li>
                  <p>The last date to upload invoices is 7 October 2022, at 17:00 (5PM).</p>
                </li>
                <li>
                  <p>Vouchers must be redeemed by 14 October 2022 at 17:00 (5PM), or you will forfeit them and be unable to participate.</p>
                </li>
                <li>
                  <p>Spins must be placed by Friday 12PM every week. Orders will be processed the following Monday and will be sent to you via SMS on the cell phone number that you registered with by the following Friday.</p>
                </li>
                <li>
                  <p>Late orders will not be accepted.</p>
                </li>
                <li>
                  <p>Prizes cannot be exchanged for cash value of prize.</p>
                </li>
              </ol>


              <h6 className="font-weight-700">Vouchers</h6>
              <ol start="13">
                <li><p>There are three (3) voucher types: Airtime, KFC, and Checkers.</p></li>
                <li><p>Airtime vouchers to be accessible for the following networks: MTN, Vodacom, Cell C and Telkom.</p></li>
                <li><p>Limited number of vouchers available.</p></li>
                <li>
                  <p>Voucher values and quantities are,</p>
                  <ul>
                    <li><p className="mb-1">R20 Airtime vouchers (1 of 3 000)</p></li>
                    <li><p className="mb-1">R50 KFC meal vouchers (1 of 1 800)</p></li>
                    <li><p className="mb-1">R100 Checkers vouchers (1 of 500)</p></li>
                  </ul>
                </li>
                <li><p>All vouchers are available while stocks last.</p></li>
                <li><p>All vouchers are issued via SMS to the registered cell phone number.</p></li>
                <li><p>Exide will not be liable to refund vouchers if the incorrect cell phone number was provided.</p></li>
                <li><p>Vouchers are accepted at nationwide branches of KFC and Checkers.</p></li>
                <li><p>Vouchers will take up to 72 hours to be sent.</p></li>
              </ol>
            </Col>
        </Row>
      </div>
    </>
)}
}

export default connect(
)(TermsAndConditions)